const en = {
  SEO_DEFAULT_TITLE:
    "Shotdok app: Archive your Scrum / Kanban sticky notes instead of discarding them",
  SEO_DEFAULT_DESCRIPTION:
    "Shotdok starts where your Scrum / Kanban board ends. Using Shotdok you can capture decisions to a work package easily and reproduce them even years later.",

  CURRENT_LANGUAGE_NAME: "English",

  LINK_HOME_NAME: "Home",
  LINK_HOME_URL: "/en/",
  LINK_IMPRINT_NAME: "Imprint",
  LINK_IMPRINT_URL: "/en/impressum",
  LINK_TERMS_OF_USE_NAME: "Terms of use",
  LINK_TERMS_OF_USE_URL:
    "https://shotdok.com/Terms-and-Conditions-Shotdok-App.pdf",
  LINK_DATA_PRIVACY_NAME: "Data privacy",
  LINK_DATA_PRIVACY_URL: "https://shotdok.com/en/data-privacy",

  HERO_TITLE_PART_1: "Archive your Scrum / Kanban sticky notes ",
  HERO_TITLE_PART_2: "instead of discarding them",
  HERO_BODY:
    "Shotdok starts where your Scrum / Kanban board ends. Using Shotdok you can capture decisions to a work package easily and reproduce them even years later.",
  HERO_CTA: "Try it now for free!",
  HERO_IMAGE_ALT:
    "An overstrained man is standing in front of a sticky note mess at a wall",

  STORE_LINK_APPLE: "https://apps.apple.com/us/app/shotdok/id1533778357",
  STORE_LINK_GOOGLE:
    "https://play.google.com/store/apps/details?id=com.brugglifting.soda",
  STORE_BADGE_APPLE_ALT: "Download Shotdok on the App Store",
  STORE_BADGE_GOOGLE_ALT: "Download Shotdok on Google Play",

  FEATURES_TITLE_TOP: "Features",
  FEATURES_TITLE_MAIN: "Four reasons to use Shotdok",
  FEATURES_DESCRIPTION: "Why you should use the app with your team.",
  FEATURE_1_NAME: "Minimal administration",
  FEATURE_1_DESCRIPTION:
    "Shotdok is designed for agile teams which would like to progress fast. The use of photos, videos and voice memos allows archiving sticky notes efficiently and does not block your work flow.",
  FEATURE_2_NAME: "Real-time collaboration",
  FEATURE_2_DESCRIPTION:
    "Each team member is able to add and manage sticky notes with their own smartphone or tablet.",
  FEATURE_3_NAME: "Reproduce decisions",
  FEATURE_3_DESCRIPTION:
    "Sticky notes and decisions are archived in a teams and topics structure. Like this they can be easily found and reproduced even years later by using the search function.",
  FEATURE_4_NAME: "Pass quality audits with ISO standards",
  FEATURE_4_DESCRIPTION:
    "By using Shotdok you will automatically conform with the ISO 9001: 2015 and ISO 14001: 2015 standards. Therefore you will survive every quality audit in the future!",

  PRICING_TITLE_TOP: "Pricing",
  PRICING_TITLE_MAIN: "Get the maximum out of your team",
  PRICING_TITLE_DESCRIPTION:
    "The pro version of Shotdok is for professional users who would like to use Shotdok with the whole team. It is possible to upgrade at any time from within the app.",
  PRICING_PERIOD: "year and team",
  PRICING_1_NAME: "Basic",
  PRICING_1_DESCRIPTION: "Basic version to give Shotdok a try.",
  PRICING_1_FEATURE_1: "Capture the physical sticky notes by photo or video",
  PRICING_1_FEATURE_2: "Enrich sticky notes with a voice memo or text",
  PRICING_1_FEATURE_3:
    "Search for old sticky notes and decisions in your teams",
  PRICING_1_FEATURE_4: "No possibility to work in teams",
  PRICING_1_FEATURE_5: "Limited to 20 sticky notes per team",
  PRICING_2_NAME: "Pro",
  PRICING_2_DESCRIPTION: "For professional users and teams.",
  PRICING_2_FEATURE_1: "Capture the physical sticky notes by photo or video",
  PRICING_2_FEATURE_2: "Enrich sticky notes with a voice memo or text",
  PRICING_2_FEATURE_3:
    "Search for old sticky notes and decisions in your teams",
  PRICING_2_FEATURE_4: "Unlimited team members",
  PRICING_2_FEATURE_5: "Unlimited sticky notes per team",

  CTA_TITLE_1: "Collaborate with your team - anytime and everywhere. ",
  CTA_TITLE_2: "Sign up for a free version today!",
  CTA_DESCRIPTION:
    "You can download and try Shotdok for Android and iOS for free. Upgrade to Shotdok Pro from within the app at any time, if you'd like to use it with your whole team.",
  CTA_IMAGE_ALT: "A happy team uses Shotdok during a Scrum meeting",

  POSTER_DOWNLOAD_TITLE: "Kanban-Scrum framework poster",
  POSTER_DOWNLOAD_DESCRIPTION:
    "This poster shows how the Kanban-Scrum framework works with Shotdok and how it conforms the ISO standards. The pink and yellow numbers reference the two ISO standards. Download it now and pin it next to your Scrum or Kanban board!",
  POSTER_DOWNLOAD_A1_URL: "/Poster_Scrum_Kanban_A1_EN.pdf",
  POSTER_DOWNLOAD_A1_TEXT: "Poster in A1 format",
  POSTER_DOWNLOAD_A3_URL: "/Poster_Scrum_Kanban_A3_EN.pdf",
  POSTER_DOWNLOAD_A3_TEXT: "Poster in A3 format",
};

export default en;
