const de = {
  SEO_DEFAULT_TITLE:
    "Shotdok App: Scrum / Kanban Merkzettel digital archivieren statt entsorgen",
  SEO_DEFAULT_DESCRIPTION:
    "Shotdok setzt da an wo dein Scrum-/Kanban-Board aufhört. Mit Shotdok kannst du Entscheidungen zu einem Arbeitspaket spielend leicht festhalten und sie auch lange Zeit später noch nachvollziehen.",

  CURRENT_LANGUAGE_NAME: "Deutsch",

  LINK_HOME_NAME: "Startseite",
  LINK_HOME_URL: "/",
  LINK_IMPRINT_NAME: "Impressum",
  LINK_IMPRINT_URL: "/impressum",
  LINK_TERMS_OF_USE_NAME: "Nutzungsbedingungen",
  LINK_TERMS_OF_USE_URL:
    "https://shotdok.com/Nutzungsbedingungen-Shotdok-App.pdf",
  LINK_DATA_PRIVACY_NAME: "Datenschutz",
  LINK_DATA_PRIVACY_URL: "https://shotdok.com/datenschutz",

  HERO_TITLE_PART_1: "Scrum / Kanban Merkzettel digital ",
  HERO_TITLE_PART_2: "archivieren statt entsorgen",
  HERO_BODY:
    "Shotdok setzt da an wo dein Scrum-/Kanban-Board aufhört. Mit Shotdok kannst du Entscheidungen zu einem Arbeitspaket spielend leicht festhalten und sie auch lange Zeit später noch nachvollziehen.",
  HERO_CTA: "Jetzt kostenlos ausprobieren!",
  HERO_IMAGE_ALT:
    "Ein Mann steht überfordert vor einem Merkzettel-Chaos an der Wand",

  STORE_LINK_APPLE: "https://apps.apple.com/de/app/shotdok/id1533778357",
  STORE_LINK_GOOGLE:
    "https://play.google.com/store/apps/details?id=com.brugglifting.soda",
  STORE_BADGE_APPLE_ALT: "Shotdok im App Store herunterladen",
  STORE_BADGE_GOOGLE_ALT: "Shotdok bei Google Play herunterladen",

  FEATURES_TITLE_TOP: "Funktionen",
  FEATURES_TITLE_MAIN: "Vier Gründe für Shotdok",
  FEATURES_DESCRIPTION: "Warum du die App mit deinem Team verwenden solltest.",
  FEATURE_1_NAME: "Minimale Administration",
  FEATURE_1_DESCRIPTION:
    "Shotdok ist für agile Teams gedacht, die schnell vorwärts kommen wollen. Die Verwendeung von Fotos, Videos und Sprachnotizen ermöglicht ein effizientes Archivieren der Merkzettel und behindert den schnellen Arbeitsablauf nicht mit mühsamen Zusatzaufwand.",
  FEATURE_2_NAME: "Zusammenarbeit in Echtzeit",
  FEATURE_2_DESCRIPTION:
    "Jedes Teammitglied kann mit dem eigenen Smartphone und Tablet Merkzettel erfassen und verwalten.",
  FEATURE_3_NAME: "Entscheidungen nachvollziehen",
  FEATURE_3_DESCRIPTION:
    "Merkzettel und Entscheidungen können strukturiert in Teams und Themenbereichen abgelegt werden. Mittels Suchfunktion können diese so auch Jahre später noch sehr schnell gefunden und nachvollzogen werden.",
  FEATURE_4_NAME: "Q-Audits nach ISO Standards bestehen",
  FEATURE_4_DESCRIPTION:
    "Durch die Verwendung von Shotdok erfüllst du automatisch die ISO 9001: 2015 und ISO 14001: 2015 Normen. So überlebst du in Zukunft jedes Q-Audit!",

  PRICING_TITLE_TOP: "Preise",
  PRICING_TITLE_MAIN: "Hole das Maximum aus deinem Team heraus",
  PRICING_TITLE_DESCRIPTION:
    "Die Pro Version von Shotdok richtet sich an professionelle Anwender*innen, die Shotdok mit dem ganzen Team verwenden möchten. Ein Upgrade ist jederzeit innerhalb der App durchführbar.",
  PRICING_PERIOD: "Jahr und Team",
  PRICING_1_NAME: "Basic",
  PRICING_1_DESCRIPTION: "Basisversion, um Shotdok zu testen.",
  PRICING_1_FEATURE_1: "Erfasse die physischen Merkzettel per Foto oder Video",
  PRICING_1_FEATURE_2: "Ergänze Merkzettel mit einer Sprachnotiz oder Text",
  PRICING_1_FEATURE_3:
    "Suche gezielt in deinen Teams nach alten Merkzetteln und Entscheidungen",
  PRICING_1_FEATURE_4: "Keine möglichkeit im Team zu arbeiten",
  PRICING_1_FEATURE_5: "Merkzettel pro Team sind auf 20 Stück begrenzt",
  PRICING_2_NAME: "Pro",
  PRICING_2_DESCRIPTION: "Für professionelle Anwender*innen und Teams.",
  PRICING_2_FEATURE_1: "Erfasse die physischen Merkzettel per Foto oder Video",
  PRICING_2_FEATURE_2: "Ergänze Merkzettel mit einer Sprachnotiz oder Text",
  PRICING_2_FEATURE_3:
    "Suche gezielt in deinen Teams nach alten Merkzetteln und Entscheidungen",
  PRICING_2_FEATURE_4: "Unlimitiert viele Mitglieder pro Team",
  PRICING_2_FEATURE_5: "Unlimitiert viele Merkzettel pro Team",

  CTA_TITLE_1: "Arbeite mit deinem Team zusammen - jederzeit und überall. ",
  CTA_TITLE_2: "Melde dich noch heute für eine kostenlose Version an!",
  CTA_DESCRIPTION:
    "Du kannst Shotdok für Android und iOS gratis herunterladen uns testen. Wenn dir die App gefällt und du sie mit deinem Team verwenden möchtest, so kannst du jederzeit direkt in der App ein Upgrade auf Shotdok Pro durchführen.",
  CTA_IMAGE_ALT:
    "Ein glückliches Team nutzt Shotdok während des Scrum Meetings",

  POSTER_DOWNLOAD_TITLE: "Kanban-Scrum Framework Poster",
  POSTER_DOWNLOAD_DESCRIPTION:
    "Dieses Poster zeigt dir auf, wie das Kanban-Scrum Framework mit Shotdok funktioniert und wie es die ISO Normen erfüllt. Die pinken und gelben Zahlen verweisen dabei auf die jeweilige ISO Norm. Lade es dir herunter und hänge es neben dein Scrum oder Kanban Board!",
  POSTER_DOWNLOAD_A1_URL: "/Poster_Scrum_Kanban_A1_DE.pdf",
  POSTER_DOWNLOAD_A1_TEXT: "Poster im A1 Format",
  POSTER_DOWNLOAD_A3_URL: "/Poster_Scrum_Kanban_A3_DE.pdf",
  POSTER_DOWNLOAD_A3_TEXT: "Poster im A3 Format",
};

export default de;
